<template>
  <div>
    <a-tabs :active-key="activeKey" @change="changeTab">
      <a-tab-pane
        v-for="item in tabs"
        :key="item.routeName"
        :tab="item.tabName"
      >
        <router-view v-if="activeKey===item.routeName" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
export default {
  name: 'CustomerServiceManagesShowTab',
  data() {
    return {
      activeKey: '',
      tabs: [
        { routeName: 'customer_service_manages_issue', tabName: '售后记录', permission: 'customer_service_manages/issue' }
      ]
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        this.activeKey = this.$route.name
      }
    }
  },
  created() {
    this.activeKey = this.$route.name
  },
  methods: {
    changeTab(activeKey) {
      this.activeKey = activeKey
      this.$router.push({ name: activeKey })
    }
  }
}
</script>
